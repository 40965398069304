<template>
  <div id="app" :key="$root.site">
    <Header v-show="$root.is_app==0" v-if="$root.site !== null && $route.name !== 'selectSite' && $route.name !== 'menu'" id="header"/>
    <div v-show="$root.is_app==0" class="error" style="background: #544842;border-color: #2d2210;" v-if="$root.site === 'abaata'">
      <div  class="additional" style="color: #fff; text-align: justify"><!--<span style="color: red; display: block">abaata-hotel-gagra.ru сайт мошенников,просим всех наших дорогих гостей быть бдительными!</span><br><br>--><b>Внимание!</b><br>
        В Отеле Абаата открылся спа центр, к услугам гостей подогреваемый бассейн с противотоком и морской водой, бассейн с гидромассажем для самых маленьких гостей, турецкий хаммам, финская сауна, русская баня на дровах, комната для релакса и закрытая терраса для воздушных ванн.
        <!--button class="button" style="background-color: green;border: 1px #5b0404 solid;">закрыть</button-->
      </div>
    </div>
    <router-view/>
    <Footer v-show="$root.is_app==0" v-if="$root.site !== null && $route.name !== 'selectSite' && $route.name !== 'menu'" id="footer"/>
    <div v-if="$route.name !== 'selectSite' && $route.name !== 'menu' && $root.site === 'abaata'"  v-show="$root.is_app==0" class="main-soc">
      <div class="main-soc__list">
        <div class="main-soc__box">
          <a href="viber://add?number=79409632799" class="main-soc__link" target="_blank" rel="nofollow">
            <img src="./assets/img/icon/soc-icon-1.svg" alt="">
          </a>
        </div>
        <div class="main-soc__box">
          <a href="https://wa.me/79409357241?text=Напишите свой вопрос и дождитесь ответа" class="main-soc__link" target="_blank" rel="nofollow">
            <img src="./assets/img/icon/soc-icon-2.svg" alt="">
          </a>
        </div>
        <div class="main-soc__box">
          <a href="https://t-do.ru/HotelAbaata" class="main-soc__link" target="_blank" rel="nofollow">
            <img src="./assets/img/icon/soc-icon-3.svg" alt="">
          </a>
        </div>
      </div>
    </div>
    <router-link v-show="$root.is_app==0" v-if="$route.name !== 'selectSite' && $route.name !== 'menu'" tag="div" :to="'/'+$root.site+'/booking'" class="x-tl-booking-widget-2215-container x-tl-booking-widget-2215-container--pulled-right" style="position: fixed; right: 10px; top: 20%; font-size: 20px;">
      <div class="x-tl-booking-widget-2215" style="opacity: 1; pointer-events: all;">
        <div class="x-tl-booking-widget-2215-icon">
          <svg class="x-tl-booking-widget-2215-svg x-tl-booking-widget-2215-svg--doorkey"
               xmlns="http://www.w3.org/2000/svg" width="50" height="40.1" viewBox="0 0 50 40.1">
            <path fill="currentColor" fill-rule="evenodd"
                  d="M16.4,9.8c-0.6-0.2-1.2-0.2-1.6-0.1c0.4,4.6,4.2,8.3,8.9,8.3c2.4,0,4.6-1,6.2-2.5c-0.4-0.4-0.9-0.7-1.4-0.8    c-1.3,1.1-3,1.8-4.8,1.8C20,16.4,16.8,13.5,16.4,9.8z M23.8,1.6c3.7,0,6.8,2.7,7.3,6.3c0.6,0,1.1,0.1,1.6,0.3    C32.3,3.6,28.5,0,23.8,0c-2.7,0-5.2,1.2-6.8,3.2c0.6,0.1,1.1,0.3,1.7,0.5C20,2.4,21.8,1.6,23.8,1.6z M18.9,18.4    c-1.5-0.8-2.8-1.9-3.7-3.3c-0.3,0-0.6,0-0.9-0.1c-2-0.5-3.2-2.5-2.7-4.5c0.5-2,2.5-3.2,4.5-2.7c1.9,0.5,3.1,2.3,2.8,4.2    c0.4,0.6,0.9,1.2,1.5,1.6c0.7-1.2,1.6-2.3,2.7-3.2C21.9,8,19.6,6,16.7,5.2C11.6,4,6.5,7.1,5.2,12.2L0.1,33.3    c-0.3,1.4,0.5,2.8,1.9,3.2l13.4,3.3c1.4,0.3,2.8-0.5,3.2-1.9l2.8-11.7c-1.6-1.9-2.5-4.3-2.5-7C18.9,18.9,18.9,18.6,18.9,18.4z    M39,22.6c0.4-0.9,0.5-1.8,0.6-2.9c0.3-5.3-3.7-9.8-9-10.1c-3-0.2-5.8,1.1-7.7,3.3c-0.5,0.5-0.8,1-1.1,1.5c0.6,0.2,1.3,0.4,2,0.4    c0.7,0,1.4-0.1,2-0.4c0.7-0.7,1.7-1.2,2.7-1.2c2,0,3.7,1.7,3.7,3.7c0,2-1.7,3.7-3.7,3.7c-1.1,0-2.1-0.5-2.8-1.2    c-0.6,0.1-1.3,0.2-2,0.2c-1.1,0-2.2-0.2-3.2-0.5c0,0.5,0,0.9,0.1,1.3c0.6,4.4,4.3,7.9,8.9,8.2c1.3,0.1,2.5-0.1,3.7-0.5l4.9,5.5    c0.1,0.1,0.2,0.2,0.3,0.2l1.8-0.2l-0.3,1.8c0,0.2,0,0.3,0.1,0.4l1,1.1c0.1,0.1,0.2,0.2,0.4,0.2l1.2-0.3l-0.3,1.1    c0,0.2,0.2,0.4,0.2,0.4l1.4,1.6l5.2,0.3c0.2,0,0.4-0.1,0.5-0.3c0-0.1,0-0.1,0.1-0.2l0.3-4.5L39,22.6z M46.6,37.1    c-0.2,0.2-0.6,0.2-0.8-0.1L35.7,25.9c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.6-0.2,0.8,0l10.1,11.2    C46.8,36.6,46.8,36.9,46.6,37.1z"></path>
          </svg>
        </div>
        <div class="x-tl-booking-widget-2215-label">Узнать цены</div>
      </div>
    </router-link>
  </div>
</template>

<script>
  import Header from './components/Header'
  import Footer from './components/Footer'
  export default {
    name: "App",
    components: {
      Header,
      Footer
    },
    computed: {
    },
    methods: {
      heightFooter () {
        document.body.style.paddingBottom = `${document.getElementById('footer').offsetHeight + 40}px`
      }
    },
    beforeMount() {
      console.log(this.$route.params.site)
      // if (this.$root.site === null) this.$router.push('/select')
    },
    mounted() {
      // this.heightFooter()
    }
  }
</script>
<style>
@-webkit-keyframes gloss {
  0% {
    left: -100%
  }
  100%, 7% {
    left: 100%
  }
}

@keyframes gloss {
  0% {
    left: -100%
  }
  100%, 7% {
    left: 100%
  }
}

.x-tl-booking-widget-2215-container {
  z-index: 1000000;
  font-size: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.x-tl-booking-widget-2215-container:not(.x-tl-booking-widget-2215-container--pulled-left) {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end;
}

.x-tl-booking-widget-2215-container--pulled-left {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
}

.x-tl-booking-widget-2215-container--pulled-left .x-tl-booking-widget-2215--hidden {
  -webkit-transform: translateX(-20em);
  transform: translateX(-20em);
}

.x-tl-booking-widget-2215-container--pulled-right .x-tl-booking-widget-2215--hidden {
  -webkit-transform: translateX(20em);
  transform: translateX(20em);
}

.x-tl-booking-widget-2215-container > *:not(:last-child) {
  margin-bottom: 1em;
}

.x-tl-booking-widget-2215-container > .x-tl-booking-widget-2215--hover:nth-child(2):not(:last-child) {
  -webkit-transition-delay: .5s;
  transition-delay: .5s;
}

.x-tl-booking-widget-2215 {
  font-size: inherit;
  position: relative;
  white-space: nowrap;
  max-width: 4em;
  height: 4em;
  background: #3f51b5;
  box-shadow: 0 .2em .9em 0 rgba(0, 0, 0, .5);
  border-radius: 2em;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-transition: all ease-in-out .5s;
  transition: all ease-in-out .5s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media screen and (min-width: 1601px) {
  .x-tl-booking-widget-2215-container {
    font-size: 25px
  }
}

@media screen and (max-width: 480px) {
  .x-tl-booking-widget-2215-container {
    font-size: 15px
  }
}

.x-tl-booking-widget-2215.x-tl-booking-widget-2215--hidden-right {
  -webkit-transform: translateX(20em);
  transform: translateX(20em);
}

.x-tl-booking-widget-2215.x-tl-booking-widget-2215--hidden-left {
  -webkit-transform: translateX(-20em);
  transform: translateX(-20em);
}

.x-tl-booking-widget-2215-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: inline-flex;
  margin-right: -.25em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 100%;
  width: 4em;
  vertical-align: middle;
  font-size: inherit;
}

.x-tl-booking-widget-2215-svg {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: block;
  color: #fff
}

.x-tl-booking-widget-2215-svg.x-tl-booking-widget-2215-svg--bell {
  width: 1.8em;
  height: 1.5em;
  margin-top: -.25em;
}

.x-tl-booking-widget-2215-svg.x-tl-booking-widget-2215-svg--doorkey {
  width: 2em;
  height: 1.6em
}

.x-tl-booking-widget-2215-svg.x-tl-booking-widget-2215-svg--ivisa {
  width: 2em;
  height: 2em;
  margin: -0.35em -0.25em 0 0;
}

.x-tl-booking-widget-2215-label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 3em;
  white-space: nowrap;
  font-family: arial, sans-serif;
  font-size: .8em;
  line-height: 1.375;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  box-sizing: border-box;
  -webkit-transition: all .6s;
  transition: all .6s;
  margin-left: 10px;
}

.x-tl-booking-widget-2215:before {
  content: " ";
  position: absolute;
  left: -100%;
  top: 0;
  display: block;
  width: 100%;
  height: 4em;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, .3) 45%, rgba(255, 255, 255, .3) 55%, rgba(255, 255, 255, 0) 80%);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, .3) 45%, rgba(255, 255, 255, .3) 55%, rgba(255, 255, 255, 0) 80%);
  -webkit-animation: gloss 10s 10s linear infinite;
  animation: gloss 10s 10s linear infinite
}

.x-tl-booking-widget-2215--ivisa .x-tl-booking-widget-2215-label {
  font-size: .7em;
}

.x-tl-booking-widget-2215--ivisa {
  background-color: #7281d5;
}

.x-tl-booking-widget-2215--ivisa .x-tl-booking-widget-2215-svg {
  color: #fff;
}

.x-tl-booking-widget-2215--ivisa .x-tl-booking-widget-2215-label-inner {
  color: #fff;
}

.x-tl-booking-widget-2215.x-tl-booking-widget-2215--hover, .x-tl-booking-widget-2215:hover {
  max-width: 1000px;
}

.x-tl-booking-widget-2215.x-tl-booking-widget-2215--hover:before, .x-tl-booking-widget-2215:hover:before {
  display: none
}
</style>
<style>
  @import "assets/css/style.css";
  #app {
  }
  .main-soc {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background-color: rgba(0, 0, 0, 0.45);
    padding: 12px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 999;
  }
  .main-soc__box:not(:last-child) {
    margin-bottom: 12px;
  }
  .error {
    max-width: 100vw;
    word-wrap: break-word;
    bottom: 0px;
    background-color: #FFEB41;
    border: 1px solid #EDDA3C;
    width: 400px;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: black;
    position: fixed;
    z-index: 10000;
    padding: 10px;
    right: 0;
  }
  @media screen and (max-width: 1700px){
    .error {
    //position: static;
      width: 100%;
    }
  }
  /*.s-price__table td:nth-child(2), .s-numbers__tabel td:nth-child(2), .s-price__table th:nth-child(2), .s-numbers__tabel th:nth-child(2) {
    background-color: #ff00005e;
  }*/
  /*.header-main > div:first-child {
    pointer-events: none;
  }*/
</style>

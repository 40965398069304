import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Nav from './components/Nav'
import Vue2TouchEvents from 'vue2-touch-events'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false
Vue.use(Vue2TouchEvents)
Vue.use(VueCookies, { expires: '5min'})
Vue.component('Nav', Nav)
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent);
  },

  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
})

var vue = new Vue({
  router,
  data () {
    return {
      site: localStorage.getItem('loadSiteNew') || null
    }
  },
  updated() {
  },
  mounted() {
    setTimeout(() => {
      if(document.getElementById('footer')) document.body.style.paddingBottom = `${document.getElementById('footer').offsetHeight + 40}px`
    }, 1000)
  },
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  console.log(to)
  if(vue.site === 'abaata') next()
  else {
    if(to.name === 'Abaata' || to.name === 'selectSite' || to.name === 'booking' || to.name === 'Offers' || to.name === 'contacts' || to.name === 'price' || to.name === 'Gallery' || to.name === 'offer' || to.name === 'services') next()
  }
})
router.afterEach((route) => {
  console.log(route.params.site)
  vue.is_app = 1
  vue.site = route.params.site
  setTimeout(() => {
    if(document.getElementById('footer')) document.body.style.paddingBottom = `${document.getElementById('footer').offsetHeight + 40}px`
  }, 1000)
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import menu from '../components/menu'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'selectSite',
    component: () => import(/* webpackChunkName: "about" */ '../components/selectSite')
  },
  {
    path: '/:site/',
    alias: '/1',
    name: 'Abaata',
    component: Home
  },
  {
    path: '/:site/menu',
    name: 'menu',
    component: menu
  },
  {
    path: '/:site/about',
    alias: '/2',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/important.vue')
  },
  {
    path: '/:site/about/awards',
    alias: '/3',
    name: 'Awards',
    component: () => import('../views/Awards.vue')
  },
  {
    path: '/:site/about/gallery',
    alias: '/4',
    name: 'Gallerys',
    component: () => import('../views/Gallerys.vue')
  },
  {
    path: '/:site/about/web-cams',
    name: 'webcam',
    component: () => import('../views/webcam.vue')
  },
  {
    path: '/:site/gallery/:id',
    name: 'Gallery',
    component: () => import('../views/Gallery.vue')
  },
  {
    path: '/:site/afisha/',
    alias: '/5',
    name: 'Afisha',
    component: () => import('../views/afisha/Afisha.vue')
  },
  {
    path: '/:site/offers',
    name: 'Offers',
    component: () => import('../views/Offers')
  },
  {
    path: '/:site/offers/:id',
    name: 'Offers',
    component: () => import('../views/Offers')
  },
  {
    path: '/:site/offer/:id',
    alias: '/6',
    name: 'offer',
    component: () => import('../views/afisha/offer')
  },
  {
    path: '/:site/restaurants',
    name: 'restaurants',
    component: () => import('../views/Restaurants')
  },
  {
    path: '/:site/restaurant/:id',
    name: 'restaurant',
    component: () => import('../views/Restaurant')
  },
  {
    path: '/:site/services',
    name: 'services',
    component: () => import('../views/Services')
  },
  {
    path: '/:site/spa',
    name: 'spa',
    component: () => import('../views/Services')
  },
  {
    path: '/:site/service/:id',
    name: 'service',
    component: () => import('../views/Service')
  },
  {
    path: '/:site/special-offers',
    alias: '/7',
    name: 'Specoffers',
    component: () => import('../views/specoffers/Specoffers')
  },
  {
    path: '/:site/important',
    alias: '/8',
    name: 'important',
    component: () => import('../views/important')
  },
  {
    //path: '/booking',
    path: '/:site/booking/:start?/:end?/:guests?/:children?',
    name: 'booking',
    component: () => import('../views/booking')
  },
  {
    path: '/:site/transfer',
    alias: '/9',
    name: 'orderTransfer',
    component: () => import('../views/order/orderTransfer')
  },
  {
    path: '/:site/docs',
    alias: '/10',
    name: 'docs',
    component: () => import('../views/docs')
  },
  {
    path: '/:site/feedback',
    alias: '/11',
    name: 'feedback',
    component: () => import('../views/feedback')
  },
  {
    path: '/:site/about/contacts',
    name: 'contacts',
    component: () => import('../views/contacts')
  },
  {
    path: '/:site/price',
    name: 'price',
    component: () => import('../views/price')
  },
  {
    path: '/:site/blog',
    alias: '/12',
    name: 'blog',
    component: () => import('../views/blog')
  },
  {
    path: '/:site/hair-clinic',
    name: 'hair-clinic',
    component: () => import('../views/hairClinic')
  },
  {
    path: '/:site/*',
    name: 'article',
    component: () => import('../views/cartBlog')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

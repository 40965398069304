<template>
    <div class="nav-page" v-show="$root.is_app==0">
        <ul>
            <router-link tag="li" :to="'/'+$root.site+'/'+url.link" v-for="url in urls" :key="url.link">{{ url.text }}</router-link>
        </ul>
    </div>
</template>

<script>
    export default {
      name: "Nav",
      props: ['urls']
    }
</script>

<style lang="scss" scoped>
    .nav-page {
        display: flex;
        ul {
            display: flex;
            margin: 24px 0;
            li {
                color: rgba(0, 0, 0, 0.4);
                font-size: 16px;
                line-height: 18px;
                position: relative;
                margin-right: 24px;
                cursor: pointer;
                &:after {
                    content: '';
                    position: absolute;
                    top: 5px;
                    right: -18px;
                    width: 12px;
                    height: 10px;
                    background: url("../assets/img/icon/arrow.svg") center no-repeat;
                }
                &:last-child {
                    color: #DCBC7F;
                    &:after {
                        background: none;
                    }
                }
            }
        }
    }
</style>

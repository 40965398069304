<template>
    <footer class="footer" v-show="$root.is_app==0" v-if="$root.site === 'abaata'">
       
        <div class="footer__inner">
            <div class="columns columns_center">
                <div class="footer__content columns__col columns__col_8 columns__col_center columns__col_sm_10">

                    <nav class="nav nav_footer footer__nav">
                        <h2 class="hidden-text">Навигация по сайту</h2>
                        <div class="nav__menu-wrapper">
                            <ul class="nav__menu footer__menu">
                                <li class="nav__item" v-if="$root.site === 'abaata'"><router-link to="/abaata/about/" class="nav__link">О комплексе</router-link>
                                </li>
                                <!--li class="nav__item"><a href="/affiche/" class="nav__link">Афиша</a>
                                </li-->
                                <li class="nav__item" v-if="$root.site === 'abaata'"><router-link :to="'/'+$root.site+'/offers/'" class="nav__link">Размещение</router-link>
                                </li>
                                <!--li class="nav__item"><a href="https://mriyamed.ru/?roistat_visit=13267168" class="nav__link">Медицина</a>
                                </li-->
                                <li class="nav__item" v-if="$root.site === 'abaata'"><router-link to="/abaata/restaurants/" class="nav__link">Бары и Рестораны</router-link>
                                </li>
                                <li class="nav__item" v-if="$root.site === 'abaata'"><router-link to="/abaata/services/" class="nav__link">Развлечения</router-link>
                                </li>
                                <!--li class="nav__item"><a href="/spa/" class="nav__link">SPA</a>
                                </li>
                                <li class="nav__item"><a href="/sport/" class="nav__link">Спорт</a>
                                </li>
                                <li class="nav__item"><a href="/kids/" class="nav__link">Детям</a>
                                </li>
                                <li class="nav__item"><a href="/events/" class="nav__link">Мероприятия</a>
                                </li-->
                            </ul><!--ul class="nav__menu footer__menu">
                            <li class="nav__item"><a href="/clinic/" class="nav__link">Клиника</a>
                            </li>
                            <li class="nav__item"><a href="/blog/" class="nav__link">Блог</a>
                            </li>
                            <li class="nav__item"><a href="https://японский-сад.рф/?roistat_visit=13267168" class="nav__link">Японский сад</a>
                            </li>
                            <li class="nav__item"><a href="/about/contacts/" class="nav__link">Перейти в контакты</a></li>
                        </ul-->
                        </div>
                    </nav>


                    <div class="contacts footer__contacts">
                        <ul class="lang footer__lang">
                        </ul>
                        <div class="contacts__row">

                            <a href="tel:+79409632799" class="wstd_phone-number contacts__tel phone-val-text">8 940 963 27 99</a>
                            <!--div class="mango-call-site" style="display: inline-block;margin-right: 15px;">
         <button style="border: 2px solid #fff;width: 175px;height: 36px;white-space: nowrap;background: none;color: #fff;font-size: 13px;cursor: pointer;text-transform: uppercase">Позвонить онлайн</button>
     </div-->


                            <p>Для звонков из-за границы РФ просим использовать телефоны: <br><a class="footer-tell roistat-phone-499" href="tel:+79409357241">+7 (940) 935 72 41</a></p>
                        </div>

                        <!--div class="two-col-right">
                            <img src="../assets/img/footer/appstore.png" alt="">
                            <img src="../assets/img/footer/google.png" alt="">
                        </div-->
                    </div>

                    <!--div class="footer__rules">
                        <ul>
                            <li><a href="/rules//" class="footer__link">Правила проживания СКК «МРИЯ»</a></li>

                            <li>
                                <a href="https://mriyaresort.com/personal-data-policy/" class="footer__link" target="_blank">
                                    Политика в отношении обработки персональных данных                          </a>
                            </li>
                            <li><a href="/personal-data-processing-policy/" target="_blank" class="footer__link">Политика в области качества</a></li>
                            <li><a href="/about/faq/" class="footer__link">Часто задаваемые вопросы</a></li>
                            <li><a href="/about/vacancies/" class="footer__link">Карьера в Mriya Resort</a></li>

                            <li><a href="/sitemap/" class="footer__link">Карта сайта</a></li>
                            <li><a href="/docs/korupciya.pdf" target="_blank" class="footer__link">О недопущении действий коррупционного характера</a></li>
                        </ul>
                    </div-->
                    <ul class="socials footer__socials">
                        <li class="socials__item"><a href="https://vk.com/hotel_abaata" target="_blank" class="socials__link">
                            <img src="../assets/img/footer/icon/vk.png" alt=""></a>
                        </li>
                        <li class="socials__item"><a href="https://www.facebook.com/critpr" target="_blank" class="socials__link">
                            <img src="../assets/img/footer/icon/fb.png" alt=""></a>
                        </li>
                        <!--li class="socials__item"><a href=" https://ok.ru/mriyaresort" target="_blank" class="socials__link">
                            <img src="../assets/img/footer/icon/ig.png" alt=""></a>
                        </li-->
                        <li class="socials__item"><a href="https://www.instagram.com/hotel_abaata/" target="_blank" class="socials__link">
                            <img src="../assets/img/footer/icon/ok.png" alt=""></a>
                        </li>
                        <!--li class="socials__item"><a href="https://www.youtube.com/channel/UCcu-lwlr8W8C4534v36AvVg?roistat_visit=13267168" target="_blank" class="socials__link">
                            <img src="../assets/img/footer/icon/yt.png" alt=""></a>
                        </li-->
                        <li class="socials__item"><a href="http://t-do.ru/HotelAbaata" target="_blank" class="socials__link">
                            <img src="../assets/img/footer/icon/tg.png" alt=""></a>
                        </li>
                        <li class="socials__item"><a href="https://wa.me/79409357241?text=Обязательно отправьте это сообщение, и дождитесь ответа." target="_blank" class="socials__link">
                            <img src="../assets/img/footer/icon/wa.png" alt=""></a>
                        </li>
                        <li class="socials__item"><a href="viber://add?number=79409632799" target="_blank" class="socials__link">
                            <img src="../assets/img/footer/icon/vb.png" alt=""></a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped lang="scss">
    .footer {
        position: absolute;bottom: 0;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 100%;
        padding-top: 3.3125rem;
        padding-bottom: 2.0625rem;
        margin-top: -1px;
        text-align: center;
        color: #fff;
        background: #625948;
        overflow: hidden;
        padding-bottom: 220px;
    }
    a:active, a:hover {
        outline: 0;
    }
    footer, nav {
        display: block;
    }
    a {
        background-color: transparent;
    }
    img {
        border: 0;
    }
    svg:not(:root) {
        overflow: hidden;
    }
    select {
        color: inherit;
        font: inherit;
        margin: 0;
    }
    select {
        text-transform: none;
    }
    .hidden-text {
        position: absolute;
        clip: rect(0 0 0 0);
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        padding: 0;
        margin: -1px;
        border: 0;
        overflow: hidden;
    }
    .columns {
        font-size: 0;
        line-height: 0;
    }
    .columns_center {
        text-align: center;
    }
    .columns_center > .columns__col {
        text-align: left;
    }
    .columns__col {
        display: inline-block;
        font-size: 1rem;
        line-height: normal;
        vertical-align: top;
    }
    .columns__col_8 {
        width: 80%;
    }
    .columns__col.columns__col_center {
        text-align: center;
    }
    @media screen and (max-width:1024px) {
        .columns__col_sm_10 {
            width: 100%;
        }
    }
    .contacts__tel {
        color: inherit;
        text-decoration: none;
    }

    .contacts__tel {
        font-weight: 100;
        font-family: PFDinTextCondPro, Arial, sans-serif;
        font-size: 1.875rem;
        line-height: 1.03333em;
        letter-spacing: -.01em;
    }
    .lang {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .lang__item {
        display: inline-block;
        border: 2px solid transparent;
        font-size: .75rem;
        line-height: 1.5em;
        vertical-align: top;
        letter-spacing: -.02em;
        transition: border .4s ease;
    }
    .lang__item:hover {
        border-color: #fff;
    }
    .made-by__text, .made-by__year {
        font-size: .8125rem;
        vertical-align: middle;
    }
    .made-by__text {
        margin-right: .75rem;
        font-weight: 300;
        line-height: 1.38462em;
    }
    .made-by__logo {
        display: inline-block;
        margin-right: .3125rem;
        line-height: 0;
        text-decoration: none;
        vertical-align: middle;
    }
    .made-by__year {
        display: inline-block;
        padding-left: .5rem;
        padding-right: .5rem;
        border: 1px solid;
        line-height: 1.1875rem;
        border-radius: 16px;
    }
    .nav {
        overflow: hidden;
    }
    .socials__icon {
        fill: currentColor;
    }
    .nav__item {
        display: inline-block;
    }
    .nav__menu-wrapper {
        margin-left: -.625rem;
        margin-right: -.625rem;
    }
    .nav__menu {
        width: 100%;
        padding: 0;
        margin: 0;
        font-size: 0;
        list-style: none;
    }
    .nav__item {
        position: relative;
        padding-right: .625rem;
        padding-left: .625rem;
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: -.045em;
        vertical-align: top;
    }
    @media screen and (max-width:1024px) {
        .nav__menu {
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-left: .3125rem;
        }
        .nav__item {
            padding-right: .5rem;
            padding-left: .5rem;
        }
    }
    .nav__item:not(:first-child)::before {
        content: '•';
        position: absolute;
        left: 0;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    .nav__link {
        position: relative;
        display: block;
        color: inherit;
        text-decoration: none;
        transition: color .4s ease;
    }
    .nav__link:hover {
        color: #ffda21;
    }
    .nav_footer {
        white-space: nowrap;
        overflow: visible;
    }
    .nav_footer .nav__item {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .socials__item:not(:last-child) {
        margin-right: .625rem;
    }
    .socials {
        margin: 0;
    }
    .socials {
        padding: 0;
        font-size: 0;
        list-style: none;
    }
    .socials__item {
        display: inline-block;
        font-size: 1rem;
        vertical-align: top;
    }
    .socials__link {
        color: inherit;
        transition: color .4s;
    }
    .socials__link:hover {
        color: #ffda21;
    }
    .socials__icon {
        width: 3.5rem;
        height: 3.5rem;
    }
    .footer__link {
        font-weight: 300;
        text-decoration: none;
    }
    .footer__nav, .footer__rules {
        margin-bottom: 1.5625rem;
    }
    .footer__bg-icon {
        position: absolute;
        left: 50%;
        bottom: -.625rem;
        z-index: 1;
        width: 17.1875rem;
        height: 14.0625rem;
        margin-left: -8.625rem;
        opacity: .2;
    }
    .footer__content, .footer__inner {
        position: relative;
    }
    .footer__inner {
        z-index: 2;
    }
    .footer__menu {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    @media screen and (max-width:1024px) {
        .footer__menu {
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }
    }
    .footer__link {
        border-bottom: 1px solid;
        font-size: .8125rem;
        line-height: 1.38462em;
        color: inherit;
        transition: border .4s;
        will-change: border;
    }
    .footer__link:hover {
        border-color: transparent;
    }
    .footer__made-by {
        position: absolute;
        bottom: .5rem;
    }
    .footer__additionally {
        bottom: 0;
        text-align: left;
    }
    .footer__awards {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1.25rem;
        font-size: 0;
        text-decoration: none;
    }
    .contacts__row {
        display: -webkit-flex;
        display: -ms-flexbox;
    }
    .application-download-link {
        margin-right: 25px;
    }
    .footer__contacts {
        position: relative;
        top: 0;
        left: 0;
    }
    .footer__lang {
        margin-bottom: 0;
    }
    .contacts__row {
        display: flex!important;
        justify-content: center!important;
        align-items: center!important;
        margin-bottom: 36px;
    }
    .footer {
        height: auto!important;
    }
    .footer__additionally {
        position: relative;
        top: 0;
        left: 0;
    }
    .footer-two-col {
        width: 100%;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 14px;
    }
    .footer-two-col__left {
        text-align: left;
    }
    .footer-two-col__right {
        text-align: right;
    }
    .nav__menu {
        text-align: none;
    }
    .footer__made-by {
        right: 14px;
    }
    .footer__contacts {
        margin-bottom: 0;
    }
    .socials.footer__socials {
    }
    #google_translate_element2 {
        display: none!important;
    }
    .contacts__row a {
        color: #fff;
    }
    .made-by__text-link {
        color: #FFF;
        text-decoration: none;
        margin-right: 0;
    }
    .made-by__text-link:hover {
        text-decoration: underline;
    }
    .made-by__logo:hover {
        opacity: .8;
    }
    ::-webkit-input-placeholder {
        color: #f4f4f4;
        opacity: .38;
    }
    :-moz-placeholder {
        color: #f4f4f4;
        opacity: .38;
    }
    ::-moz-placeholder {
        color: #f4f4f4;
        opacity: .38;
    }
    :-ms-input-placeholder {
        color: #f4f4f4;
        opacity: .38;
    }
    .footer__menu {
        text-align: center;
    }
    .footer__contacts {
        margin-bottom: 0;
    }
    .footer__contacts .contacts__row {
        display: inline-block!important;
    }
    .footer__contacts .footer__lang {
        display: inline-block;
        float: left;
        text-align: left;
        width: 18%;
        padding: 0 0 0 10px;
    }
    .application-download-link {
        margin-right: 15px;
    }
    .footer__contacts .two-col-right {
        display: inline-block;
        float: right;
        @media (max-width: 500px) {
            float: initial;
        }
    }
    .contacts__tel {
        line-height: 36px;
        display: block;
        float: left;
    }
    .footer__rules ul {
        margin: 0;
        padding: 0 10px 30px;
        list-style: none;
        display: flex;
        justify-content: space-between;
        @media (max-width: 500px) {
            flex-direction: column;
            margin-top: 20px;
            li {
                margin: 10px 0;
                opacity: 0.6;
            }
        }
    }
    .application-download-link {
        border: 2px solid #fff;
        display: block;
        float: left;
        padding: 5px 0;
        height: 36px;
    }
    .application-download-link img {
        width: 36px;
        max-height: 100%;
    }
    .footer__made-by {
        bottom: .5rem;
        right: 14px;
    }
    .made-by__logo {
        margin: .2rem 0 .4rem;
    }
    .made_by__txt a {
        font-size: .6rem;
    }
    .footer-two-col {
        padding: 0 10px;
    }
    .footer-two-col__right {
        text-align: left;
    }
    .goog-te-gadget {
        font-family: arial;
        font-size: 11px;
        color: #666;
        white-space: nowrap;
    }
    .goog-te-gadget img {
        vertical-align: middle;
        border: none;
    }
    .goog-te-combo {
        margin-left: 4px;
        margin-right: 4px;
        vertical-align: baseline;
        *vertical-align: middle;
    }
    .goog-te-gadget .goog-te-combo {
        margin: 4px 0;
    }
    .goog-logo-link, .goog-logo-link:link, .goog-logo-link:visited, .goog-logo-link:hover, .goog-logo-link:active {
        font-size: 12px;
        font-weight: bold;
        color: #444;
        text-decoration: none;
    }
    .goog-te-combo {
        font-family: arial;
        font-size: 10pt;
    }
</style>

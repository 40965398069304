<template>
<div v-touch:swipe.right="swipeRight"  v-touch:swipe.left="swipeLeft">
  <object><embed :src="'/img/menu/' + file + page + '.pdf'" width="100%" height="100%"/></object>
  <div style="position: fixed; z-index: 999; bottom: 5px; left: 50%; margin-right: -50%; transform: translate(-50%);">

  </div>
  <button @click="swipeRight" style="position: fixed;left: 0;top: 0;">&lt;</button>
  <button @click="swipeLeft" style="position: fixed;right: 0;top: 0;">&gt;</button>
</div>
</template>

<script>
import $ from 'jquery'
/* eslint-disable */
export default {
  name: 'menu',
  data: function () {
    return {
      file: '2022-',
      page: 1
    }
  },
  mounted () {
    $('html').addClass('menuRotate')
  },
  methods: {
    swipeRight () {
      var page = this.page
      page--
      if (page < 1) page = 2
      this.page = page
    },
    swipeLeft () {
      var page = this.page
      page++
      if (page > 2) page = 1
      this.page = page
    }
  },
  watch: {
    file () {
      /*
      if (this.file === 'news') {
        $('html').addClass('menuRotate')
        $('html').removeClass('barRotate')
      } else {
        $('html').addClass('barRotate')
        $('html').removeClass('menuRotate')
      }
      */
    }
  },
  beforeDestroy () {
    $('html').removeClass('barRotate').removeClass('menuRotate')
  }
}
</script>

<style scoped>
button {
  display: inline;
  //padding: 17px 13px 15px 13px;
  padding: 5px 10px 5px 10px;
  width: auto;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  line-height: 1.42;
  border: 0;
  background-color: #9d8b79;
  cursor: pointer;
  outline: none;
  -webkit-transition: background-color .1s ease;
  -o-transition: background-color .1s ease;
  transition: background-color .1s ease;
}

</style>
<style>
html.menuRotate body, html.barRotate body {
  min-height: unset;
  min-width: unset;
  overflow: unset;
}
html.menuRotate embed, html.barRotate embed {
  height: calc(100vh - 7px);width: 100vw;
}
html.menuRotate .error, html.barRotate .error {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  html.menuRotate {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
  html.menuRotate embed {
    width: calc(100vh - 7px);height: 100vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html.barRotate {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
  html.barRotate embed {
    width: calc(100vh - 7px);height: 100vw;
  }
}
</style>
